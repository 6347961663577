$(document).ready(function() {
    $(".vpop").on('click', function(e) {
        e.preventDefault();
        console.log("click!!");
        $("#video-popup-overlay,#video-popup-video-container,#video-popup-container,#video-popup-close").show();

        var videoSrc = $(this).data('id');
        var videoElement = $("#video-popup-video")[0];
        videoElement.src = videoSrc;
        videoElement.autoplay = true;
        videoElement.muted = true;
        videoElement.loop = true;
        videoElement.load();

        $("#video-popup-container").show();

        $('body *').not('#video-popup-container, #video-popup-container *').addClass('blur-background');

        $('#crs1').addClass('cursor-fade');
        $('#crs2').addClass('cursor-fade');

        // Add event listeners for scroll and touchmove
        $(window).on('scroll touchmove', closeAndUnblur);

        // Add event listener to toggle video playback on click
        $("#video-popup-video").on('click', function() {
            if (videoElement.paused) {
                videoElement.play();
            } else {
                videoElement.pause();
            }
        });

        // Add event listener to toggle audio on double click
        var clickCount = 0;
        var timeout;
        $("#video-popup-video").on('click', function() {
            clickCount++;
            if (clickCount === 1) {
                timeout = setTimeout(function() {
                    clickCount = 0;
                }, 300); // Change the delay if needed
            } else if (clickCount === 2) {
                clearTimeout(timeout);
                if (videoElement.muted) {
                    videoElement.muted = false;
                } else {
                    videoElement.muted = true;
                }
                clickCount = 0;
            }
        });
    });

    // Function to close the popup and remove the blur
    function closeAndUnblur() {
        $("#video-popup-video-container,#video-popup-container,#video-popup-close,#video-popup-overlay").hide();
        $("#video-popup-video")[0].src = "";
        $("body *").removeClass('blur-background');

        $('#crs1').removeClass('cursor-fade');
        $('#crs2').removeClass('cursor-fade');

        // Remove the event listeners to prevent unwanted closing after the popup is already closed
        $(window).off('scroll touchmove', closeAndUnblur);

        // Remove the click event listener on the video
        $("#video-popup-video").off('click');
    }

    // Close and remove blur when the close button or overlay is clicked
    $("#video-popup-close, #video-popup-overlay").on('click', function(e) {
        closeAndUnblur();
    });

    // Add this to close the popup when clicking outside of it
    $(document).on('click', function(e) {
        if (!$(e.target).closest('#video-popup-container').length && !$(e.target).is('.vpop')) {
            closeAndUnblur();
        }
    });
});
